<template>
  <r-e-dialog title="退单" :visible.sync="dialogVisible" width="600px"  >
    <el-form ref="formPublish" label-width="90px" size="small" :model="formPublish">
      <el-form-item label="退单原因"
                    :rules="[
      { required: true, message: '请输入退单原因', trigger: 'blur' },
      { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur,change' }]">
        <el-input type="textarea" v-model="formPublish.chargebackCause"></el-input>
      </el-form-item>
      <el-form-item
          style="text-align: center"

      >
        <el-button style="margin-left: -50px;margin-bottom: -120px" type="primary" @click="handleSubmitPublish">确认</el-button>
        <el-button  @click="handleCancel">取消</el-button>
      </el-form-item>
    </el-form>
  </r-e-dialog>
</template>

<script>



import {issueRoomList} from "@/api/lock-permission";
import REDialog from "@/components/Gloabl/r-e-dialog/index.vue";
import {postrefundAPI} from "@/api/home";
import {MessageSuccess, MessageWarning} from "@custom/message";

export default {
  name: "go-details",
  components: {REDialog},
  data() {
    return {
      formPublish: {
        chargebackCause:null
      },
      dialogVisible: false,
      roomList: [],
      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      },
      status: null,
    }
  },
  async created() {
  },
  methods: {
    async openDialog(row) {
      const {uuid, status} = row;
      this.uuid = uuid;
      this.dialogVisible = true;
    },
    handleSubmitPublish(){
      let data = {
        uuid:this.uuid,
        chargebackCause:this.formPublish.chargebackCause
      }
      postrefundAPI(data).then(res => {
        console.log(res)
        this.dialogVisible = false;
        MessageSuccess('退单成功');
        this.$emit('clickCancel')
        this.formPublish.chargebackCause = null



      }).catch(err => {
        console.log(err)
      })

    },
    handleCancel(){
      this.formPublish.chargebackCause = null
      this.dialogVisible = false;
    }
  },
}
</script>

<style lang="scss" scoped>

</style>